@tailwind base;
@tailwind components;
@tailwind utilities;



html {
  scroll-behavior: smooth;
}

body {
  background-color: #121212; /* Replace #yourColor with your desired color code */
}
